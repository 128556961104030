import { Outlet, Link } from "react-router-dom";
import NavBar from './NavBar'
import CardTransformer from './CardTransformer';
import CardMultimeter from './CardMultimeter';

const Layout = () => {
    return (
        <>
        <Outlet />
      </>
      );
};

export default Layout;