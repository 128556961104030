import React, { Component } from 'react';
import { Outlet, Link } from "react-router-dom";

class CardMultimeter extends Component {

    constructor(props){
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            timer: 0,
            id: 0,
            text: ""
        }

    }

    render(){
        const { error, isLoaded, timer, text } = this.state;
        return(
            <nav>
                <Link to={'/multimeter/'+this.props.idModbus}>
                    <div className="col">
                        <div className="card text-center" style={{ width: '18rem', display: 'block', border: 'none'}}>
                            <img src='/img/multimeter.png' className="card-img" alt="..." style={{width: '15%', height: '15%'}}/>
                            <div className="card-body">
                                <h3 className="card-title">Multimetro</h3>
                                <p className="card-text">{text}</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </nav>
        );
    }
}

export default CardMultimeter;
