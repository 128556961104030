import React, { PureComponent } from 'react';
import { useRef, useState, useEffect } from 'react';
import { add, format, differenceInCalendarDays, isFuture } from "date-fns";
import {
  Label,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts';

import  useAxiosPrivate  from '../hooks/useAxiosPrivate';

const CURRENT_SERIES_URL = 'measure/currentseries/240';

const dateFormatter = date => {
    return format(new Date(date * 1000), "HH:mm:ss dd/MMM/yy");
  };

const initialData = [
    { timestamp: 1601820360000, I1: 4.11 , I2: 4.11 , I3: 4.11},
    { timestamp: 1601820362000, I1: 2.39  , I2: 4.11 , I3: 4.11},
    { timestamp: 1601820364000, I1: 1.37  , I2: 4.11 , I3: 4.11},
    { timestamp: 1601820366000, I1: 1.16  , I2: 4.11 , I3: 4.11},
    { timestamp: 1601820368000, I1: 2.29  , I2: 4.11 , I3: 4.11},
    { timestamp: 1601820370000, I1: 3 , I2: 4.11 , I3: 4.11 },
    { timestamp: 1601820372000, I1: 0.53 , I2: 4.11 , I3: 4.11 },
  ];

 

function findTimestamp(data, timestamp) {
  return data.timestamp === timestamp;
}

const getAxisYDomain = (data, from, to, ref, offset) => {
    var fromIndex = 0;
    var toIndex = 0;
    var i = 0;
    data.forEach((element) => {
      if(element.timestamp === from){
        fromIndex = i;
      }
      if(element.timestamp === to){
        toIndex = i;
      }      
      i++;
    });

    const refData = data.slice(fromIndex - 1, toIndex);
    let [bottom, top] = [refData[0][ref], refData[0][ref]];
    refData.forEach((d) => {
      if (d[ref] > top) top = d[ref];
      if (d[ref] < bottom) bottom = d[ref];
    });
  
    return [(bottom | 0) - offset, (top | 0) + offset];
  };
  
  const initialState = {
    data: initialData,
    left: 'dataMin',
    right: 'dataMax',
    refAreaLeft: '',
    refAreaRight: '',
    top: 'dataMax+1',
    bottom: 'dataMin-1',
    top2: 'dataMax+20',
    bottom2: 'dataMin-20',
    top3: 'dataMax+40',
    bottom3: 'dataMin-40',
    animation: true
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(payload);
      return (
        <div className="custom-tooltip" style={ {backgroud: 'white'}, {border: 'inset'} }>
          <p className="label">{dateFormatter(label)}</p>
          <p>{ payload[0].dataKey } : {payload[0].payload.i1}</p>
          <p>{ payload[1].dataKey } : {payload[1].payload.i2}</p>
          <p>{ payload[2].dataKey } : {payload[2].payload.i3}</p>
        </div>
      );
    }
  
    return null;
  };

  export default class CurrentPlot extends PureComponent {
    
      
    constructor(props) {
      super(props);
      this.state = initialState;
      this.state.data = props.data;

      console.log(this.state);
    }

    

    async requestCurrentSeries (){
      console.log("Id Modbus: " + this.props.idModbus);
      console.log("Request current series");
      try {
        const response = await this.axiosPrivate.get(CURRENT_SERIES_URL,
          { withCredentials: false,
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        });
        console.log("Request current series");
        console.log(JSON.stringify(response?.data));
    } catch (err) {
        if (!err?.response) {
            //setErrMsg('No Server Response');
            console.log("Errore 1!!!");
        } else if (err.response?.status === 400) {
            //setErrMsg('Missing Username or Password');
            console.log("Errore 2!!!");
        } else if (err.response?.status === 401) {
            //setErrMsg('Unauthorized');
            console.log("Errore 3!!!");
        } else {
            //setErrMsg('Login Failed');
            console.log("Errore 4!!!");
        }
        //errRef.current.focus();
      }
    }

    componentDidMount() {
      console.log("Data from parent");
    }
  
    zoom() {
      let { refAreaLeft, refAreaRight } = this.state;
      const { data } = this.state;

      if (refAreaLeft === refAreaRight || refAreaRight === '') {
        this.setState(() => ({
          refAreaLeft: '',
          refAreaRight: '',
        }));
        return;
      }
  
      // xAxis domain
      if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

      
      console.log(refAreaLeft);
      console.log(refAreaRight);
  
      // yAxis domain
      const [bottom, top] = getAxisYDomain(this.state.data, refAreaLeft, refAreaRight, 'i1', 1);
      const [bottom2, top2] = getAxisYDomain(this.state.data, refAreaLeft, refAreaRight, 'i2', 10);
      const [bottom3, top3] = getAxisYDomain(this.state.data, refAreaLeft, refAreaRight, 'i3', 20);
      //const [bottom2, top2] = getAxisYDomain(refAreaLeft, refAreaRight, 'impression', 50);
  
      this.setState(() => ({
        refAreaLeft: '',
        refAreaRight: '',
        data: data.slice(),
        left: refAreaLeft,
        right: refAreaRight,
        bottom,
        top,
        bottom2,
        top2,
        bottom3,
        top3,
      }));
    }
  
    zoomOut() {
      const { data } = this.state;
      console.log(this.state);
      this.setState(() => ({
        data: data.slice(),
        refAreaLeft: '',
        refAreaRight: '',
        left: 'dataMin',
        right: 'dataMax',
        top: 'dataMax+1',
        bottom: 'dataMin',
        top2: 'dataMax+10',
        bottom2: 'dataMin',
        top3: 'dataMax+20',
        bottom3: 'dataMin',
      }));
    }

    
  
    render() {
      const { data, barIndex, left, right, refAreaLeft, refAreaRight, top, bottom, top2, bottom2, top3, bottom3 } = this.state;
  
      return (
        <div className="highlight-bar-charts" style={{ userSelect: 'none', width: '100%' }}>
          <button type="button" className="btn update" onClick={this.zoomOut.bind(this)}>
            Visualizza Dati
          </button>
          <span>      </span>
          <button type="button" className="btn update" onClick={this.zoomOut.bind(this)}>
            Zoom Out
          </button>
          
  
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={800}
              height={400}
              data={data}
              onMouseDown={(e) => this.setState({ refAreaLeft: e.activeLabel })}
              onMouseMove={(e) => this.state.refAreaLeft && this.setState({ refAreaRight: e.activeLabel })}
              // eslint-disable-next-line react/jsx-no-bind
              onMouseUp={this.zoom.bind(this)}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                    allowDataOverflow
                    dataKey="timestamp"
                    scale="time"
                    tickFormatter={dateFormatter}
                    domain={[left, right]} 
                    type="number" />
              <Tooltip content={<CustomTooltip />}/>
              <Legend />
              <Line yAxisId="1" type="natural" dataKey="i1" stroke="#8884d8" animationDuration={300} />
              <Line yAxisId="2" type="natural" dataKey="i2" stroke="#8800d8" animationDuration={300} />
              <Line yAxisId="3" type="natural" dataKey="i3" stroke="#4884d8" animationDuration={300} />
  
              {refAreaLeft && refAreaRight ? (
                <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
              ) : null}
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    }
  }