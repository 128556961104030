import React, { Component } from 'react';
//import logo from '../logo.svg'

class CardTransformer extends Component {
    render(){
        return(
            <div className="col">
                <div className="card text-center square={true} " style={{ width: '18rem', display: 'block', border: 'none'}}>
                    <img src='/img/transformer.png' className="card-img" alt="..." style={{width: '20%', height: '20%'}}/>
                    <div className="card-body">
                        <h5 className="card-title"></h5>
                    </div>
                </div>
            </div>
        );
    }
}

export default CardTransformer;