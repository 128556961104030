import { useNavigate, Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";


import 'bootstrap/dist/css/bootstrap.css';
import './NavBar.css';


function NavBar(){
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();

    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        navigate('/linkpage');
    }




    return(
        <nav className="navbar navbar-dark" style={{background: '#db2723'}}>
            <div className='container-fluid'>
                <span className='navbar-brand  mb-0 h1'>E-Monitoring</span>
                <button onClick={logout}>Sign Out</button>
            </div>
        </nav>
    );
}

export default NavBar;
