import { useNavigate, Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import NavBar from "./NavBar";
import CardTransformer from "./CardTransformer";
import CardMultimeter from "./CardMultimeter";

const Home = () => {
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();

    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        navigate('/linkpage');
    }

    return (
        <>
        <NavBar />
        <div className='container'>
            <h2>Multimetri</h2>
            <hr />
            <div className='row'>
              <div className="col"></div>
              <div className="col"><CardTransformer /></div>
              <div className="col"><CardTransformer/></div>
              <div className="col"><CardTransformer/></div>
              <div className="col"></div>
              <div className="w-100"></div>
              <div className="col"></div>
              <div className="col"><CardMultimeter idModbus='241'/></div>
              <div className="col"><CardMultimeter idModbus='242'/></div>
              <div className="col"><CardMultimeter idModbus='243'/></div>
              <div className="col"></div>
              <div className="w-100"></div>
              <div className="col"></div>
              <div className="col"><CardMultimeter idModbus='240' /></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="w-100"></div>            
              <div className="col"><CardMultimeter idModbus='237'/></div>
              <div className="col"><CardMultimeter idModbus='238'/></div>
              <div className="col"><CardMultimeter idModbus='239'/></div>
              <div className="col"></div>
              <div className="col"></div>
            </div>          
        </div>
        </>
    )
}

export default Home