import axios from "axios";
const BASE_URL = "https://devices.dnmp.it/";
//const BASE_URL = "http://localhost:8668/";

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
    withCredentials: true
});