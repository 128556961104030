import { useRef, useState, useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { add, format, differenceInCalendarDays, isFuture } from "date-fns";
import MeasuresChart from './MeasuresChart';
import NavBar from "./NavBar";

import  useAxiosPrivate  from '../hooks/useAxiosPrivate';

const LAST_MEASURE_URL = 'measure/lastmeasure/';
const CURRENT_SERIES_URL = 'measure/currentseries/';


const dateFormatter = date => {
  return format(new Date(date), "HH:mm:ss dd/MMM/yyyy");
};

const MultimeterDetails = () => {

  let { idModbus } = useParams();

  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [lastTimestamp, setLastTimestamp] = useState(1);

  const [measures, setMeasures] = useState({});
  const [currentSeries, setCurrentSeries] = useState([{ timestamp: 1601820360000, I1: 4.11 , I2: 4.11 , I3: 4.11}]);
  const [currentSeriesLoaded, setCurrentSeriesLoaded] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }

  const requestCurrentSeries = async () => {
    console.log("Id Modbus: " + idModbus);
    try {
      const response = await axiosPrivate.get(CURRENT_SERIES_URL + idModbus,
        { withCredentials: false,
          headers: {
              'Access-Control-Allow-Origin': '*'
          }
      });
      console.log(JSON.stringify(response?.data));
      setCurrentSeries(response?.data);
      setCurrentSeriesLoaded(true);
  } catch (err) {
      if (!err?.response) {
          setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Login Failed');
      }
      //errRef.current.focus();
    }
  }

  const requestData = async () => {
    console.log("Id Modbus: " + idModbus);
    try {
      const response = await axiosPrivate.get(LAST_MEASURE_URL + idModbus,
        { withCredentials: false,
          headers: {
              'Access-Control-Allow-Origin': '*'
          }
      });
      console.log(JSON.stringify(response?.data));
      setLastTimestamp(response?.data.timestamp * 1000);
      setMeasures(response?.data);
  } catch (err) {
      if (!err?.response) {
          setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Login Failed');
      }
      //errRef.current.focus();
    }
  }

  useEffect(() => {
    requestData().catch(console.err);;
    requestCurrentSeries().catch(console.err);
  }, []);



    return (
      <>      
      <NavBar />
        <div className='container'>
          <h2>Dettagli Multimetro</h2>
          <br />
          <div className='row'>
            <div className='col-md-5'>
              <h3>Frequenza</h3>
              <br />
              <p>Rete: {measures.frequency} Hz</p>
            </div>
            <div className='col-md-5'>
              <h3>Fattore di Potenza</h3>
              <br />
              <p>Cos(phi): {measures.power_factor}</p>
            </div>
            <div className='col-md-2'>
              <p>Ultimo aggiornamento: { dateFormatter(lastTimestamp) }</p>
            </div>
          </div>
          <div className='row'>
                    <div className='col-md-4'>
                        <table className="table">
                            <thead className="thead-dark">
                            <tr>
                                <th >Tensioni di Fase</th>
                            </tr>
                            <tr>
                                <th scope="col">Fase</th>
                                <th scope="col">Valore</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <th scope="row">L1</th>
                                <td>{measures.voltage_L1} V</td>
                                </tr>
                                <tr>
                                <th scope="row">L2</th>
                                <td>{measures.voltage_L2} V</td>
                                </tr>
                                <tr>
                                <th scope="row">L3</th>
                                <td>{measures.voltage_L3} V</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    
                    <div className='col-md-4'>
                        <table className="table">
                            <thead className="thead-dark">
                            <tr>
                                <th>Tensioni Concatenate</th>
                            </tr>
                            <tr>
                                <th scope="col">Fase</th>
                                <th scope="col">Valore</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <th scope="row">L1</th>
                                <td>{measures.voltage_L1L2} V</td>
                                </tr>
                                <tr>
                                <th scope="row">L2</th>
                                <td>{measures.voltage_L2L3} V</td>
                                </tr>
                                <tr>
                                <th scope="row">L3</th>
                                <td>{measures.voltage_L3L1} V</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div className='col-md-4'>
                        <table className="table">
                            <thead className="thead-dark">
                            <tr>
                                <th>Correnti</th>
                            </tr>
                            <tr>
                                <th scope="col">Fase</th>
                                <th scope="col">Valore</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <th scope="row">L1</th>
                                <td>{measures.current_L1} A</td>
                                </tr>
                                <tr>
                                <th scope="row">L2</th>
                                <td>{measures.current_L2} A</td>
                                </tr>
                                <tr>
                                <th scope="row">L3</th>
                                <td>{measures.current_L3} A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
          <hr />
          <div className='row'>
          <table className="table">
            <thead className="thead-dark">
            <tr>
                <th >Energia</th>
            </tr>
            <tr>
                <th scope="col">-</th>
                <th scope="col">Valore</th>
            </tr>
            </thead>
              <tbody>
                <tr>
                <th scope="row">Energia Attiva Positiva</th>
                <td>{measures.positive_active_energy} Wh</td>
                </tr>
                <tr>
                <th scope="row">Energia Reattiva Positiva</th>
                <td>{measures.positive_reactive_energy} varh</td>
                </tr>
                <tr>
                <th scope="row">Energia Attiva Negativa</th>
                <td>{measures.negative_active_energy} Wh</td>
                </tr>
                <tr>
                <th scope="row">Energia Reattiva Negativa</th>
                <td>{measures.negative_reactive_energy} varh</td>
                </tr>
              </tbody>
          </table>

          </div>

          <div className='row'>
          <table className="table">
            <thead className="thead-dark">
            <tr>
                <th >Potenza</th>
            </tr>
            <tr>
                <th scope="col">-</th>
                <th scope="col">Valore</th>
            </tr>
            </thead>
              <tbody>
                <tr>
                <th scope="row">Potenza Attiva</th>
                <td>{measures.active_power} W</td>
                </tr>
                <tr>
                <th scope="row">Potenza Reattiva</th>
                <td>{measures.reactive_power} var</td>
                </tr>
                <tr>
                <th scope="row">Potenza Apparente</th>
                <td>{measures.apparent_power} VA</td>
                </tr>
              </tbody>
          </table>

          </div>

          <div className='row'>
          <h3>Andamento delle correnti</h3>
              <br />
              {currentSeriesLoaded
                ? <MeasuresChart data={currentSeries}/>
                : <p>Caricamento in corso...</p>
              }            
          </div>


        </div>
       </>
    );
  };
  
export default MultimeterDetails;